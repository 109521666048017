<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Travel Requests</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <p>
                                    The Travel Requests Export provides a list of participants who requested
                                    airfare and/or room reservations for future workshop sessions.
                                </p>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button variant="success" @click="doExport">Export</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import {errorModalOptions} from "../../../../util/formatters";


@Component
export default class TravelRequests extends Vue {
    async doExport() {
        try {
            await download('POST', '/api/v1/export/travel-requests', JSON.stringify({}));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Travel Requests', null, true));
    }
}
</script>


<style scoped>

</style>